import axios from "axios";
import Cookies from "universal-cookie";

export const errorResponse = (error) => {
  console.log(error);
  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
};

export const authTokenHeader = {
  Authorization: "Bearer " + new Cookies().get("user_token"),
};

export default axios.create({
  baseURL: "https://memberportal.dev.fitdatamax.net/",
});
